<script setup lang="ts">
import safeBoxWithGoldenCoin from '@images/misc/3d-safe-box-with-golden-dollar-coins.png'
import spaceRocket from '@images/misc/3d-space-rocket-with-smoke.png'
import dollarCoinPiggyBank from '@images/misc/dollar-coins-flying-pink-piggy-bank.png'

interface Pricing {
  title?: string
  xs?: number | string
  sm?: number | string
  md?: string | number
  lg?: string | number
  xl?: string | number
}

const props = defineProps<Pricing>()

const pricingPlans = [
  {
    name: 'Free',
    tagLine: 'Free for everyone',
    logo: dollarCoinPiggyBank,
    yearlyPrice: 0,
    isPopular: false,
    current: false,
    features: [
      'Free feature 1st',
      'Free feature 2nd',
      'Free feature 3rd',
    ],
  },
  {
    name: 'Basic',
    tagLine: '261 days remaining to get renewed',
    logo: safeBoxWithGoldenCoin,
    yearlyPrice: 590,
    isPopular: true,
    current: true,
    features: [
      'Automated compliant share ledger',
      'Cap Table & Investoren-Management',
      'General Assemblies',
      'Secure data room',
      'Selected legal documents',
      'Investor communication',
      'Marketplace with discounts',
      'Unlimited number of administrators',
    ],
  },
  {
    name: 'Premium',
    tagLine: 'For small to medium businesses',
    logo: spaceRocket,
    yearlyPrice: '1\'990',
    isPopular: false,
    current: false,
    features: [
      'All Features of the Basic Package',
      'Agenda item templates',
      'Board meetings',
      'Automated meeting minutes',
      'Automated tax & holding confirmations',
      'Evaluations and analyses',
    ],
  },
]
</script>

<template>
  <!-- 👉 Title and subtitle -->
  <div class="k-ez9d65">
    <h3 class="k-dscp6q mb-2 text-h3 pricing-title">
      {{ props.title ? props.title : 'Pricing Plans' }}
    </h3>
    <p class="mb-0">
      Konsento follows a transparent pricing structure. We only charge for explicitly and individually ordered additional services.
    </p>
    <p class="mb-2">
      Choose the best plan to fit your needs.
    </p>
    <p class="k-x8qlw6">
      32 of up to 50 investors added
      <span class="k-16l4j6 ml-2 d-mkflex">
        <a
          href="https://konsento.ch/"
          target="noopener noreferrer"
          class="k-dscp6q"
        >
          {{ $t('subscription.viewInvestors') }}
        </a>
      </span>
    </p>
  </div>

  <!-- SECTION pricing plans -->
  <VRow class="pricing-plan-container mt-12">
    <VCol
      v-for="plan in pricingPlans"
      :key="plan.logo"
      v-bind="props"
      cols="12"
    >
      <!-- 👉  Card -->
      <VCard
        flat
        border
        :class="plan.isPopular ? 'border-primary border-opacity-100' : ''"
      >
        <VCardText
          style="block-size: 3.75rem;"
          class="text-end"
        >
          <!-- 👉 Popular -->
          <VChip
            v-show="plan.isPopular"
            label
            color="primary"
            size="small"
          >
            Popular
          </VChip>
        </VCardText>

        <!-- 👉 Plan logo -->
        <VCardText>
          <VImg
            :height="120"
            :width="120"
            :src="plan.logo"
            class="mx-auto mb-5"
          />

          <!-- 👉 Plan name -->
          <h4 class="k-ez9d65 mb-1 text-h4">
            {{ plan.name }} - Growth
          </h4>
          <p class="k-ez9d65 mb-0 text-body-1">
            {{ plan.tagLine }}
          </p>

          <!-- 👉 Plan price  -->

          <div class="k-lq393u">
            <div class="k-x8qlw6 pt-5 pb-10">
              <div class="text-body-1 font-weight-medium align-self-start">
                CHF
              </div>
              <h1 class="k-dscp6q font-weight-medium text-h1">
                {{ plan.yearlyPrice }}
              </h1>
              <div class="text-body-1 font-weight-medium align-self-end">
                per year
              </div>
            </div>

            <!-- 👉 Annual Price -->
            <span
              v-show="annualMonthlyPlanPriceToggler"
              class="k-tfqp0j text-disabled annual-price-text text-caption pb-4"
            >
              {{ plan.yearlyPrice === 0 ? 'free' : `USD ${plan.yearlyPrice}/Year` }}
            </span>
          </div>

          <!-- 👉 Plan features -->

          <VList class="card-list mb-4">
            <VListItem
              v-for="feature in plan.features"
              :key="feature"
            >
              <template #prepend>
                <VIcon
                  v-if="plan.name === 'Basic' || plan.name === 'Free'"
                  size="8"
                  icon="tabler-circle-filled"
                  color="rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))"
                />
                <VIcon
                  v-else
                  size="16"
                  icon="tabler-star"
                  color="rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))"
                />
              </template>

              <VListItemTitle class="k-ffczgw text-body-1">
                {{ feature }}
              </VListItemTitle>
            </VListItem>
          </VList>

          <!-- 👉 Plan actions -->
          <AppButton
            block
            :color="plan.current ? 'success' : 'primary'"
            :variant="plan.isPopular ? 'elevated' : 'tonal'"
            :active="false"
            size="default"
          >
            {{ plan.isPopular ? 'Your Current Plan' : plan.yearlyPrice === 0 ? 'Downgrade' : 'Upgrade' }}
          </AppButton>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
  <!-- !SECTION  -->
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1rem;
}

.save-upto-chip {
  inset-block-start: -2.4rem;
  inset-inline-end: -6rem;
}

.annual-price-text {
  inset-block-end: 3%;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.pricing-plan-container {
  justify-content: space-around;
}
</style>
